import React from 'react';
import classNames from 'classnames';
import { flowRight } from 'lodash';
import PropTypes from 'prop-types';

import {
  SECTIONS,
  SIDE_BY_SIDE_LAYOUT_IMAGE_LEFT,
  SIDE_BY_SIDE_LAYOUT_IMAGE_RIGHT,
  SIDE_BY_SIDE_LAYOUT_IMAGE_NONE,
  getPostCover,
  resolveId,
} from '@wix/communities-blog-client-common';

import CommentCountCompact from '../../../../comments/components/comment-count-compact';
import { ThreeDotsIcon } from '../../../../common/components/icons/three-dots-icon';
import LikeButtonWithCount from '../../../../common/components/like-button-with-count';
import Link from '../../../../common/components/link/internal-link';
import { MoreButton } from '../../../../common/components/more-button';
import { importPostActions } from '../../../../common/components/post-actions';
import PostHeaderIcons from '../../../../common/components/post-header-icons';
import PostListItemCategoryLabel from '../../../../common/components/post-list-item-category-label';
import PostListItemCover from '../../../../common/components/post-list-item-cover';
import PostListItemHeader from '../../../../common/components/post-list-item-header';
import PostTitle from '../../../../common/components/post-title';
import RatingsDisplay, {
  RatingsDisplayLayout,
} from '../../../../common/components/ratings-display';
import { connect } from '../../../../common/components/runtime-context';
import { HorizontalSeparatorForPostCard } from '../../../../common/components/separator';
import ViewCountCompact from '../../../../common/components/view-count-compact';
import withAuth from '../../../../common/hoc/with-auth';
import withFeedBorderWidth from '../../../../common/hoc/with-feed-border-width';
import withFeedMetadataSettings from '../../../../common/hoc/with-feed-metadata-settings';
import withFontClassName from '../../../../common/hoc/with-font-class-name';
import withIsFeedDesignEnabled from '../../../../common/hoc/with-is-feed-design-enabled';
import withLayoutColorClasses from '../../../../common/hoc/with-layout-color-classes';
import withPermissions from '../../../../common/hoc/with-permissions';
import withPostFontSize from '../../../../common/hoc/with-post-font-size';
import {
  getSBSLayoutImagePosition,
  getIsMoreButtonEnabled,
  getMobileContentAlignment,
} from '../../../../common/selectors/app-settings-selectors';
import { getSection } from '../../../../common/selectors/section-selectors';
import { getContentAlignmentStyles } from '../../../../common/services/content-alignment-helpers';
import {
  getLineCounts,
  getFeedColorClassName,
} from '../../../../common/services/layout-config';
import { getPostActions } from '../../../../common/services/post-actions';
import { getIsRTL } from '../../../../common/store/basic-params/basic-params-selectors';
import { getIsMemberAreaInstalled } from '../../../../common/store/communities-context/communities-context-selectors';

import {
  getViewCount,
  getCommentCount,
} from '../../../../common/store/post-counters/post-counters-selectors';
import {
  getPostAverageRating,
  getPostTotalRatings,
} from '../../../../common/store/post-ratings/post-ratings-selector';
import alignmentStyles from '../../../../common/styles/post-list-item-alignment.scss';
import postListItemStyles from '../../../../common/styles/post-list-item.scss';
import styles from './side-by-side.scss';

export const PostListItemSideBySide = ({
  borderWidth,
  contentFontClassName,
  forPublicUser,
  getPostClassName,
  iconColorClassName,
  itemConfig,
  onLikeClick,
  post,
  postMetadataFontSize,
  postTitleFontSize,
  showCommentCount,
  showLikeCount,
  showViewCount,
  sideBySideLayoutImagePosition,
  titleFontClassName,
  type,
  showMoreOptionsMenu,
  isMoreButtonEnabled,
  showAuthorName,
  showReadingTime,
  showPublishDate,
  viewCount,
  totalComments,
  can,
  showCategoryLabel,
  alignment,
  isRTL,
  showPostRating,
  postTotalRatings,
  postAverageRating,
  section,
}) => {
  const { shouldRender: withCover } = getPostCover(post);
  const lineCounts = getLineCounts(itemConfig, withCover);
  const postLink = `/${post.slug}`;

  const containerClassName = classNames(
    styles.container,
    alignmentStyles[alignment],
    contentFontClassName,
    'blog-text-color',
    'blog-card-background-color',
    'blog-card-border-color',
    'post-list-item',
    getPostClassName(
      'border-color',
      'post-container',
      getFeedColorClassName(type, 'background-color'),
    ),
  );

  const contentWrapperClassName = classNames(
    styles.flexContainer,
    sideBySideLayoutImagePosition === SIDE_BY_SIDE_LAYOUT_IMAGE_LEFT &&
      styles.pullImageLeft,
    isRTL && alignmentStyles.isRTL,
  );

  const linkClassName = classNames(
    styles.textWrapper,
    post.isPinned && styles.withIcons,
  );
  const headerClassName = classNames(
    styles.header,
    contentFontClassName,
    alignmentStyles.headerContainer,
  );

  const titleStyle = { fontSize: postTitleFontSize };
  const showCommentStats =
    showCommentCount && (totalComments > 0 || !post.isCommentsDisabled);
  const moreButtonId = `more-button-${resolveId(post)}`;

  const footerClassName = classNames(
    getPostClassName(
      'description-font',
      getFeedColorClassName(type, 'description-color'),
    ),
    styles.footer,
  );

  const countersVisible = showLikeCount || showCommentStats || showViewCount;
  const isImagePositionNone =
    sideBySideLayoutImagePosition === SIDE_BY_SIDE_LAYOUT_IMAGE_NONE;
  const showFooter =
    isMoreButtonEnabled || countersVisible || !isImagePositionNone;
  const showMoreButtonInHeading =
    isMoreButtonEnabled && !countersVisible && isImagePositionNone;

  const showPostCover = getPostCover(post).shouldRender && !isImagePositionNone;

  const coverWrapperClassName = classNames(
    styles.imageContainer,
    sideBySideLayoutImagePosition === SIDE_BY_SIDE_LAYOUT_IMAGE_LEFT &&
      styles.rightMargin,
  );

  const moreButtonIcon = (
    <ThreeDotsIcon
      className={classNames(
        iconColorClassName,
        'blog-hover-container-element-fill',
      )}
    />
  );

  const isMoreButtonInHeadingEnabled =
    showMoreOptionsMenu &&
    showMoreButtonInHeading &&
    !showFooter &&
    isMoreButtonEnabled;
  const showHeader =
    isMoreButtonInHeadingEnabled ||
    showAuthorName ||
    showReadingTime ||
    showPublishDate;
  const separatorClass = showFooter
    ? styles.separator
    : classNames(styles.separator, styles.withoutFooter);

  return (
    <article
      className={containerClassName}
      tabIndex="0"
      style={{ borderWidth }}
      data-hook="post-list-item"
    >
      <div className={contentWrapperClassName}>
        <div
          className={classNames(
            styles.titleContainer,
            alignmentStyles.textAlign,
          )}
        >
          <Link to={postLink} className={linkClassName} addHoverClasses={false}>
            <PostTitle
              type={type}
              title={post.title}
              lineCount={lineCounts.title}
              style={titleStyle}
              className={classNames(
                styles.title,
                titleFontClassName,
                showCategoryLabel && styles.withCategoryLabel,
              )}
            />
          </Link>
          {showCategoryLabel && (
            <PostListItemCategoryLabel
              className={alignmentStyles.categoryLabel}
              post={post}
              postListLayout={type}
            />
          )}
          {showPostRating && postTotalRatings ? (
            <RatingsDisplay
              className={classNames(
                postListItemStyles.rating,
                postListItemStyles[section],
                styles.rating,
                styles[type],
                alignmentStyles.rating,
              )}
              rating={postAverageRating}
              count={postTotalRatings}
              layout={RatingsDisplayLayout.with_range}
            />
          ) : null}
          {showHeader && (
            <PostListItemHeader
              className={headerClassName}
              fixedMetadataHeight={false}
              post={post}
              showHeaderIcons={false}
              showMoreButton={isMoreButtonInHeadingEnabled}
              showProfileImage={false}
              style={{ fontSize: postMetadataFontSize }}
              moreButtonIcon={moreButtonIcon}
              moreButtonClass={styles.moreButtonInHeading}
              type={type}
            />
          )}
        </div>
        {showPostCover && (
          <div className={coverWrapperClassName}>
            <PostListItemCover
              post={post}
              postLink={postLink}
              type={type}
              canPlayVideo
              isPublic={can('share', 'post', post)}
              videoClassName={styles.videoEmbed}
              imageClassName={styles.image}
              isMobile
            />
          </div>
        )}
      </div>
      {showFooter && (
        <div className={footerClassName}>
          {countersVisible && (
            <div
              className={styles.socialButtons}
              style={{ fontSize: postMetadataFontSize }}
            >
              {showLikeCount && (
                <div className={styles.socialButton}>
                  <LikeButtonWithCount
                    onClick={forPublicUser(() => onLikeClick(resolveId(post)))}
                    entity={post}
                    className="post-footer__like-button"
                    showZero
                    switchCounterAndButton
                  />
                </div>
              )}
              {showViewCount && (
                <div
                  className={classNames(
                    'description-font',
                    styles.socialButton,
                  )}
                >
                  <ViewCountCompact count={viewCount} tabIndex={0} />
                </div>
              )}
              {showCommentStats && (
                <div
                  className={classNames(
                    'description-font',
                    styles.socialButton,
                  )}
                >
                  <CommentCountCompact
                    displayIcons={itemConfig.displayFooterIcons}
                    count={totalComments}
                    showZero
                    tabIndex={0}
                  />
                </div>
              )}
            </div>
          )}
          <PostHeaderIcons
            post={post}
            className={styles.icons}
            iconClassName="blog-button-fill"
          />
          {isMoreButtonEnabled && (
            <div className="blog-more-icon-fill">
              <MoreButton
                className={styles.moreButton}
                id={moreButtonId}
                icon={moreButtonIcon}
              >
                {async () => {
                  const PostActions = await importPostActions();
                  return (
                    <PostActions post={post} focusOnCloseId={moreButtonId} />
                  );
                }}
              </MoreButton>
            </div>
          )}
        </div>
      )}
      <HorizontalSeparatorForPostCard className={separatorClass} />
    </article>
  );
};

PostListItemSideBySide.propTypes = {
  borderWidth: PropTypes.number,
  contentFontClassName: PropTypes.string.isRequired,
  contentFontClassNameWithStyle: PropTypes.string.isRequired,
  forPublicUser: PropTypes.func,
  getPostClassName: PropTypes.func.isRequired,
  iconColorClassName: PropTypes.string.isRequired,
  itemConfig: PropTypes.object.isRequired,
  onLikeClick: PropTypes.func.isRequired,
  post: PropTypes.object,
  postDescriptionFontSize: PropTypes.number.isRequired,
  postMetadataFontSize: PropTypes.number.isRequired,
  postTitleFontSize: PropTypes.number.isRequired,
  section: PropTypes.oneOf(SECTIONS),
  showCommentCount: PropTypes.bool,
  showCommentStats: PropTypes.bool,
  showLikeCount: PropTypes.bool,
  showMoreButton: PropTypes.bool,
  showPostDescription: PropTypes.bool,
  showViewCount: PropTypes.bool,
  sideBySideLayoutImagePosition: PropTypes.oneOf([
    SIDE_BY_SIDE_LAYOUT_IMAGE_LEFT,
    SIDE_BY_SIDE_LAYOUT_IMAGE_RIGHT,
    SIDE_BY_SIDE_LAYOUT_IMAGE_NONE,
  ]),
  titleFontClassName: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  showMoreOptionsMenu: PropTypes.bool,
  isMoreButtonEnabled: PropTypes.bool,
  showAuthorName: PropTypes.bool,
  showReadingTime: PropTypes.bool,
  showPublishDate: PropTypes.bool,
  viewCount: PropTypes.number.isRequired,
  totalComments: PropTypes.number.isRequired,
  showCategoryLabel: PropTypes.bool,
  alignment: PropTypes.string,
  showPostRating: PropTypes.bool,
  postAverageRating: PropTypes.number,
  postTotalRatings: PropTypes.number,
};

PostListItemSideBySide.defaultProps = {
  showViewCount: true,
  showCommentCount: true,
  showLikeCount: true,
};

const mapRuntimeToProps = (state, { post, canSee }) => {
  const isRTL = getIsRTL(state);
  const contentAlignmentMobile = getMobileContentAlignment(state, isRTL);
  const postActions = getPostActions({
    post,
    canSee,
    enableShare: true,
    enableSubscribe: getIsMemberAreaInstalled(state),
  });
  const section = getSection(state);
  const showMoreButton = Boolean(postActions.length);
  const postId = resolveId(post);

  return {
    showMoreButton,
    section,
    sideBySideLayoutImagePosition: getSBSLayoutImagePosition(state, section),
    isMoreButtonEnabled: getIsMoreButtonEnabled(state, showMoreButton),
    viewCount: getViewCount(state, postId),
    totalComments: getCommentCount(state, postId),
    alignment: getContentAlignmentStyles(contentAlignmentMobile, isRTL),
    isRTL,
    postAverageRating: getPostAverageRating(state, resolveId(post)),
    postTotalRatings: getPostTotalRatings(state, resolveId(post)),
  };
};

export default flowRight(
  withLayoutColorClasses,
  withPermissions,
  withFontClassName,
  withPostFontSize,
  withFeedMetadataSettings,
  withFeedBorderWidth,
  withIsFeedDesignEnabled,
  withAuth,
  connect(mapRuntimeToProps),
)(PostListItemSideBySide);
